import { toRefs, watch, reactive } from 'vue';

const state = reactive({
  showing: false,
  message: '',
  type: 'success',
});

export default (timeout = 3000) => {
  function show (message, opts = { type: 'success' }) {
    state.showing = true;
    state.message = message;
    state.type = opts.type;
  }

  function hide () {
    state.showing = false;
  }

  watch(state, (newVal) => {
    if (newVal.showing) {
      setTimeout(() => {
        hide();
      }, timeout);
    }
  });

  return {
    ...toRefs(state),
    show,
    hide,
  };
};
